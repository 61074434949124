export const config = {
  companyLogo: '/logo/logo.png',
  brandColors: {
    PRIMARY: {
      lighter: '#e0cb8c',
      light: '#d7bd6d',
      main: '#CFAF4E',
      dark: '#c19e34',
      darker: '#a2852c',
      contrastText: '#000',
    },

    SECONDARY: {
      lighter: '#0474b3',
      light: '#035b8c',
      main: '#024266',
      dark: '#012940',
      darker: '#001019',
      contrastText: '#FFFFFF',
    },

    INFO: {
      lighter: '#bfd0da',
      light: '#a6bdcb',
      main: '#8dabbd',
      dark: '#7499af',
      darker: '#5c869f',
      contrastText: '#000',
    },

    SUCCESS: {
      lighter: '#02de97',
      light: '#01b77d',
      main: '#019062',
      dark: '#016947',
      darker: '#00422d',
      contrastText: '#ffffff',
    },

    WARNING: {
      lighter: '#0474b3',
      light: '#035b8c',
      main: '#024266',
      dark: '#012940',
      darker: '#001019',
      contrastText: '#000',
    },

    ERROR: {
      lighter: '#e08e7b',
      light: '#d8735c',
      main: '#D1583C',
      dark: '#ba472c',
      darker: '#9a3b24',
      contrastText: '#FFFFFF',
    },
  },
  productPage: {
    productCarousel: {
      show: true,
      grid: {
        md: 6,
        lg: 7,
      },
    },
    productDescription: {
      show: true,
      grid: {
        md: 6,
        lg: 5,
      },
    },
    dataTab: {
      show: true,

      tempData: {
        show: true,
        grid: {
          md: 6,
          lg: 6,
        },
      },
      energyData: {
        show: true,
        grid: {
          md: 6,
          lg: 6,
        },
      },
      volumeData: {
        show: true,
        grid: {
          md: 6,
          lg: 6,
        },
      },
      weightData: {
        show: true,
        grid: {
          md: 6,
          lg: 6,
        },
      },
      allData: {
        show: true,
        grid: {
          md: 12,
          lg: 12,
        },
        dataTypes: [
          'test template 4',
          'Address2',
          'ID',
          'Amount',
          'Energy',
          'energy',
          'File Upload',
          'file',
          'Image',
          'image',
          'Location',
          'GeoJSON',
          'Temperature',
          'temperature',
          'Volume',
          'volume',
          'Weight',
          'weight',
          'Dimensions',
          'dimension',
          'Address',
          'Phone Number',
          'Monetary Value',
          'Sex',
          'Distance',
          'Email',
          'email',
          'URL',
          'Date',
          'date',
          'Number',
          'number',
          'Text',
          'text',
          'Boolean',
          'boolean',
          'Long Text',
          'textarea',
          'select',
          'Path',
          'path',
          'Area',
          'area',
        ],
      },
    },
    trackTraceTab: {
      show: true,
    },
    attachmentsTab: {
      show: true,
    },
    reviewsTab: {
      show: true,
    },
    companyTab: {
      show: false,
      content: {
        header: 'Company Header',
        subheader: 'Company Subheader',
        longText: 'Company Long text',
        pictures: ['/assets/images/about/what_1.png', '/assets/images/about/what_2.png'],
        button: { label: 'Button', href: 'https://unisot.com/' },
      },
    },
    infoTab: {
      show: true,
    },
    certificates: {
      show: true,
    },
    loyalty: {
      show: false,
    },
    partners: {
      show: false,
      content: {
        header: 'Our Partners Header',
        subheader: 'Our Partners Subheader',
        longText: 'Our Partners Long text',
      },
      partnersList: [
        {
          header: 'Parner 1',
          subheader: 'Parner 1 Subheader',
          logo: '/assets/images/about/logo.png',
          buttons: [
            { name: 'facebook', href: 'https://unisot.com/', icon: 'eva:facebook-fill' },
            { name: 'homepage', href: 'https://unisot.com/', icon: 'eva:linkedin-fill' },
          ],
        },
        {
          header: 'Partner 2',
          subheader: 'Partner 2 Subheader',
          logo: '/assets/images/about/logo.png',
          buttons: [
            { name: 'facebook', href: 'https://unisot.com/', icon: 'eva:facebook-fill' },
            { name: 'homepage', href: 'https://unisot.com/', icon: 'eva:linkedin-fill' },
          ],
        },
        {
          header: 'Partner 3',
          subheader: 'Partner 3 Subheader',
          logo: '/assets/images/about/logo.png',
          buttons: [
            { name: 'facebook', href: 'https://unisot.com/', icon: 'eva:facebook-fill' },
            { name: 'homepage', href: 'https://unisot.com/', icon: 'eva:linkedin-fill' },
          ],
        },
      ],
    },
  },
};
