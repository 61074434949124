import { Navigate, useRoutes } from 'react-router-dom';
import { lazy, Suspense } from 'react';

// layouts
import MainLayout from 'src/layouts/main';
// config
// import { PATH_AFTER_LOGIN } from 'src/config-global';
//
import DashboardLayout from 'src/layouts/dashboard';
import NotFoundPage from 'src/pages/404';
import { mainRoutes, HomePage } from './main';
import { authRoutes } from './auth';
import { authDemoRoutes } from './auth-demo';
import { dashboardRoutes } from './dashboard';
import { componentsRoutes } from './components';

const ProductDetailsPage = lazy(() => import('src/pages/dashboard/product/details'));

// ----------------------------------------------------------------------

export default function Router() {
  return useRoutes([
    // SET INDEX PAGE WITH SKIP HOME PAGE
    // {
    //   path: '/',
    //   element: <Navigate to={PATH_AFTER_LOGIN} replace />,
    // },

    // ----------------------------------------------------------------------

    // SET INDEX PAGE WITH HOME PAGE
    {
      path: '/',
      element: <Navigate to="/home" replace />,
    },
    {
      path: '/home',
      element: (
        <MainLayout>
          <HomePage />
        </MainLayout>
      ),
    },
    {
      path: '/:param1',
      element: (
        <MainLayout>
          <ProductDetailsPage />
        </MainLayout>
      ),
    },
    {
      path: '/:param1/:param2/:param3/:param4/:param5/:param6',
      element: (
        <MainLayout>
          <ProductDetailsPage />
        </MainLayout>
      ),
    },
    {
      path: '/:param1/:param2/:param3/:param4',
      element: (
        <MainLayout>
          <ProductDetailsPage />
        </MainLayout>
      ),
    },
    {
      path: '/:param1/:param2',
      element: (
        <MainLayout>
          <ProductDetailsPage />
        </MainLayout>
      ),
    },
    {
      path: '/404',
      element: <NotFoundPage />,
    },

    // No match 404
    //  { path: '*', element: <Navigate to="/404" replace /> },
  ]);
}
